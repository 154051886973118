<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Mi seguimiento
            <v-spacer></v-spacer>

            <v-btn 
              color="primary" 
              small 
              @click="initialize()"
              tile
            >Actualizar</v-btn>
          </v-card-title>

          <v-card-text>
            <v-row >
              <v-col cols="12" sm="4" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="fechaini"
                  hide-details
                  dense
                  filled
                  type="date"
                  label="Fecha inicio"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="fechafin"
                  hide-details
                  dense
                  filled
                  type="date"
                  label="Fecha final"
                  clearable
                ></v-text-field>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12" md="6" lg="5">
                <v-text-field
                label="Buscar Prospecto"
                dense
                v-model="search"
                filled
                append-icon="mdi-magnify"
                dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Asignación de contactos</span>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
            
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="10">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <!--Lista de prospectos-->
                <v-data-table
                  :headers="headers2"
                  :items="contadorVendedoras"
                  class="elevation-0"
                  :search="search2"
                  dense
                >

                  <template v-slot:item.convencimiento="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.convencimiento }}
                        </span>
                      </template>
                      <span>{{ item.desglose }}</span>
                    </v-tooltip>
                  </template>


                  <template v-slot:item.contactos="{ item }">
                    <v-chip small dense color="primary" dark @click="verContactos( item.contactosGeneral )">{{ item.contactos }}</v-chip>
                  </template>

                  <template v-slot:item.inscritos="{ item }">
                    <v-chip small dense color="primary" dark @click="verInscritos( item.inscritos_detalle )">{{ item.inscritos }}</v-chip>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Reporte por vendedoras</span>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
            
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="10">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="vendedoras"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.utlimocontacto="{ item }">
                    <v-icon color="green" small right v-if="item.minutos < 20">mdi-circle</v-icon>
                    <v-icon color="yellow" small right v-if="item.minutos >= 20 && item.minutos <= 59 ">mdi-circle</v-icon>
                    <v-icon color="red" small right v-if="item.minutos >= 60">mdi-circle</v-icon>
                    <span @click="mensaje = item.utlimomensaje, dialogVerMensaje = true">{{ item.utlimocontacto }}</span>
                  </template>

                  <template v-slot:item.espera="{ item }">
                    <v-btn 
                      color="orange" 
                      x-small
                      @click="tabla = item.espera_C, dialogContactos = true"
                      class="mr-2"
                      dark
                    >
                      {{item.espera}}
                    </v-btn>
                  </template>

                  <template v-slot:item.verdes="{ item }">
                    <v-btn 
                      color="green" 
                      x-small
                      @click="tabla = item.verdes_C, dialogContactos = true"
                      class="mr-2"
                      dark
                    >
                      {{item.verdes}}
                    </v-btn>
                  </template>

                  <template v-slot:item.amarillos_mios="{ item }">
                    <v-btn 
                      color="yellow" 
                      x-small
                      @click="tabla = item.amarillos_mios_C, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.amarillos_mios}}
                    </v-btn>
                  </template>

                  <template v-slot:item.rojos_mios="{ item }">
                    <v-btn 
                      color="red" 
                      x-small
                      @click="tabla = item.rojos_mios_C, dialogContactos = true"
                      class="mr-2"
                      dark
                    >
                      {{item.rojos_mios}}
                    </v-btn>
                  </template>


                  <template v-slot:item.verdes_mios="{ item }">
                    <v-btn 
                      color="green" 
                      x-small
                      @click="tabla = item.verdes_mios_C, dialogContactos = true"
                      class="mr-2"
                      dark
                    >
                      {{item.verdes_mios}}
                    </v-btn>
                  </template>

                  <template v-slot:item.amarillos="{ item }">
                    <v-btn 
                      color="yellow" 
                      x-small
                      @click="tabla = item.amarillos_C, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.amarillos}}
                    </v-btn>
                  </template>

                  <template v-slot:item.rojos="{ item }">
                    <v-btn 
                      color="red" 
                      x-small
                      @click="tabla = item.rojos_C, dialogContactos = true"
                      class="mr-2"
                      dark
                    >
                      {{item.rojos}}
                    </v-btn>
                  </template>

                  <template v-slot:item.nuevos="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="tabla = item.nuevos_c, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.nuevos}}
                    </v-btn>
                  </template>

                  <template v-slot:item.atrasados="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="tabla = item.atrasados_C, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.atrasados}}
                    </v-btn>
                  </template>

                  <template v-slot:item.coninfo="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="tabla = item.coninfo_c, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.coninfo}}
                    </v-btn>
                  </template>

                  <template v-slot:item.sininfo="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="tabla = item.sininfo_c, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.sininfo}}
                    </v-btn>
                  </template>

                  <template v-slot:item.contacto2="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="tabla = item.contacto2_c, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.contacto2}}
                    </v-btn>
                  </template>

                  <template v-slot:item.porcontestar="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="tabla = item.porcontestar_c, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.porcontestar}}
                    </v-btn>
                  </template>
                  <template v-slot:item.amarillos="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="tabla = item.amarillos_C, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.amarillos}}
                    </v-btn>
                  </template>

                  <template v-slot:item.verdes="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="tabla = item.verdes_C, dialogContactos = true"
                      class="mr-2"
                    >
                      {{item.verdes}}
                    </v-btn>
                  </template>

                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                      small
                    >
                      Actualizar
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn 
              color="black"
              dark
              small
              tile
              @click="dialogVendedora.estatus = false"
            >
              <v-icon small left>mdi-close</v-icon>
              Cerrar
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogContactos"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Contactos 
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersContactos"
            :items="tabla"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVerMensaje"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Último mensaje enviado 
        </v-card-title>
        <v-card-text v-if="mensaje" class="black--text">
          Usuario: {{ mensaje.usuario }}
          <br/>
          Fecha: {{ mensaje.fecha_ultimo_mensaje }}
          <br/>
          Mensaje: 
          <br/>
          <div>
            <!-- Tipo de texto es textooo -->
            <p 
              class="text" 
              v-if="mensaje.type == 1"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              {{ mensaje.mensaje }}
            </p>

            <!-- Tipo de texto es Imagennnnn -->
            <p 
              class="text" 
              v-if="mensaje.type == 2"
              style="white-space: pre-line"
              @click="verImagen( mensaje.MediaUrl0 )"
              type="button"
              @contextmenu="show(mensaje, $event)"
            >
              <img v-if="mensaje.mio == 0 && !mensaje.fake" :src="url + mensaje.MediaUrl0" alt="Red dot" width="300" />
              <img v-if="mensaje.mio == 1 && !mensaje.fake" :src="url2 + mensaje.MediaUrl0" alt="Red dot" width="300" />

              <!-- Ruta para imagenes fake -->
              <img v-if="mensaje.fake" :src="mensaje.imagenFake" alt="Red dot" width="300" />
            </p>

            <!-- Para cuando se envían stickers -->
            <p 
              class="text" 
              v-if="mensaje.type == 'sticker'"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              <img v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0" alt="Red dot" width="150"/>
              <img v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0" alt="Red dot" width="150"/>
            </p>

            <!-- Tipo PDF -->
            <p 
              class="text" 
              v-if="mensaje.type == 4"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              <v-chip
                color="#f1f3f4"
                label
                text-color="black"
              >
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>

              </v-chip>
                <embed v-if="mensaje.mio == 0 && !mensaje.fake" :src="url + mensaje.MediaUrl0 " type="application/pdf"  width="100%" height="350px" />
                <embed v-if="mensaje.mio == 1 && !mensaje.fake" :src="url2 + mensaje.MediaUrl0 " type="application/pdf" width="100%" height="350px" />

                <embed v-if="mensaje.fake" :src="`${mensaje.imagenFake}#toolbar=0`" type="application/pdf" width="100%" height="350px" />
            </p>

            <!-- Tipo Audio -->
            <p 
              class="text pa-0 pt-2 px-2 audio" 
              v-if="mensaje.type == 3"
              style="white-space: pre-line; width: 300px;"
              @contextmenu="show(mensaje, $event)"
            >
              <audio controls style="width: 100%;">
                <source v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0" type="audio/ogg" >
                <source v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0" :type="`audio/${mensaje.MediaUrl0.substring(mensaje.MediaUrl0.length - 3, mensaje.MediaUrl0.length) == 'mp4' ? 'mp4' : 'mp3'}`" >
              </audio>
            </p>

            <!-- Tipo video -->
            <p 
              class="text" 
              v-if="mensaje.type == 5"
              style="white-space: pre-line"
            >
              <video controls width="300">
                <source v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0">
                <source v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0">
              </video>
            </p>

            <!-- Mensaje -->
            <p 
              class="text mt-2" 
              v-if="mensaje.type == 2 && mensaje.mensaje"
              style="white-space: pre-line"
            >
              {{ mensaje.mensaje }}
            </p>

            <!-- Mensaje -->
            <p 
              class="text mt-2" 
              v-if="mensaje.type == 4 && mensaje.mensaje"
              style="white-space: pre-line"
            >
              {{ mensaje.mensaje }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            absolute
            top
            right
            @click="dialogVerMensaje = false"
          ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>               
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogMensajes"
      max-width="1400px"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Datos de los contactos
          <v-spacer></v-spacer>
          <v-btn 
            class="mr-10" 
            small dark color="green"
            @click="exportExcel( tablaContactos  , 'MENSAJES')"
            tile
          >
            <v-icon small>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-radio-group
                v-model="opZona"
                row
              >
                <v-radio
                  v-for="(base, i) in baseEscuela"
                  :key="i"
                  :label="base.zona"
                  :value="base.zona"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <div
                v-for="(base, i) in baseEscuela"
                :key="i"
                v-if="base.zona != 'TODOS'"
              >
                {{ base.zona }} : {{ tablaContactos.filter( el => el.sistema == base.zona ).length }} 
              </div>
              <div
                v-for="(base, i) in baseEscuela"
                :key="i"
                v-if="base.zona == 'TODOS'"
              >
                {{ base.zona }} : {{ tablaContactos.length }} 
              </div>
            </v-col>

          </v-row>
          <v-data-table
            :headers="headers3"
            :items="filterTablaContactos"
            class="elevation-0"
            dense
            mobile-breakpoint="200"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
        <v-btn 
          tile
          top
          absolute
          icon
          right
          @click="dialogMensajes = false"
        >
          <v-icon small left>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Mostrar todos los alumnos inscritos -->
    <v-dialog
      v-model="dialogAlumnos"
      max-width="1400px"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          DAtos de los alumnos inscritos
          <v-spacer></v-spacer>
          <v-btn 
            class="mr-10" 
            small dark color="green"
            @click="exportExcel( tablaAlumnos  , 'MENSAJES')"
            tile
          >
            <v-icon small>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersAlumnos"
            :items="tablaAlumnos"
            class="elevation-0"
            dense
            mobile-breakpoint="200"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
        <v-btn 
          tile
          top
          absolute
          icon
          right
          @click="dialogAlumnos = false"
        >
          <v-icon small left>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar2"/>

  </v-container>
</template>


<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import basicosTablas         from '@/mixins/basicosTablas'
  import SeguimientoVendedora  from '@/components/leads/SeguimientoVendedora'

  export default {
    components:{
      Alerta,
      carga,
      SeguimientoVendedora,
    },

    mixins: [ validarErrores, funcionesExcel, basicosTablas ],
    
    //Data
    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      //datos que se desplegaran en las 2 tablas
      prospectos: [],
      usuarios: [],
      usuario: null,
      dialogUsuario: false,
      dialog: false,

      headers: [
        { text: "Folio"    , value: "folio" },
        { text: "Plantel"  , value: "plantel" },
        { text: "Telefono" , value: "telefono" },
        { text: "Nombre"   , value: "nombre_completo" },
        { text: "Vendedora", value: "name" },
        { text: "Estatus"  , value: "estatus" },
        { text: "Completo" , value: "completo" },
        { text: "Match"    , value: "match" },
        { text: "Fecha"    , value: "fecha_creacion" },
      ],

      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      search: "",

      escuela: 1,

      vendedoras: [],

      headers2:[
        // { text: "Position"          , value: "position" },
        { text: "Vendedora"         , value: "vendedora" },
        // { text: "% carga laboral"   , value: "carga" },
        // { text: "Contactos"         , value: "contactosall" },
        // { text: "Ventas al momento" , value: "position_ventas_momento" },
        // { text: "Diferencia"        , value: "diferencia" },
        { text: "% convencimiento"  , value: "convencimiento" },
        { text: "Contactos hoy"     , value: "contactos" },
        { text: "Inscritos"         , value: "inscritos" },

        // { text: "ranking"           , value: "ranking" },
        // { text: "puedeRecibir"      , value: "puedeRecibir" },
      ],

      search2:'',

      contactosVsFecha:[],
      contactosInscritos:[],

      cursos_escuela:[],
      medios_contacto:[],

      searchTelefono:'',

      editedItem: {
        nombre_completo:'',
        idleds:'',
        escuela: 1,
        iddetalle_fuentes:0,
        idfuentes: 0,
        fuente: '',
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
        respondio: 0,
      },

      sucursales:[],
      sucursal:null,
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,
      fuentes:[],

      dialogTwilio: {
        estatus: false
      },

      dialogVendedora: {
        estatus: false
      },

      dialogDiferencias: {
        estatus: false
      },

      headers: [
        { text: "Vendedora"        , value: "vendedora"        },
        { text: "Último Contacto"  , value: "utlimocontacto"   },
        { text: "T. Espera"        , value: "espera"           },
        { text: "Verdes"           , value: "verdes"           },
        { text: "Amarillos"        , value: "amarillos"        },
        { text: "Rojo"             , value: "rojos"            },
        
        { text: "Verdes M salida"    , value: "verdes_mios"   },
        { text: "Amarillos M salida" , value: "amarillos_mios"},
        { text: "Rojo M salida"      , value: "rojos_mios"    },
        
        { text: "Atrasados"     , value: "atrasados"    },
        { text: "Nuevos"        , value: "nuevos"       },
        { text: "Con info"      , value: "coninfo"      },
        { text: "Sin info"      , value: "sininfo"      },
        { text: "2da etapa"     , value: "contacto2"    },
        { text: "Por contestar" , value: "porcontestar" },
      ],

      headersContactos:[
        { text: "Folio"         , value: "folio"                  },
        { text: "Telefono"      , value: "usuario"                },
        { text: "Nombre"        , value: "nombre_completo"        },
        { text: "Zona"          , value: "zona"        },
        { text: "T. Espera"     , value: "tiempo_espera"          },
      ],
      tabla:[],
      dialogContactos: false,
      cargar2: false,

      mensaje: null,
      dialogVerMensaje: false,
      url :'https://testfast.club/imagenes-whatsapp/',
      url2: 'https://escuelakpi.club/imagenes-whatsapp/',

      contactosActivos:[],
      contactosVsFecha:[],
      contactosInscritos:[],
      prospectos:[],
      baseEscuela:[],
      totalMatriculas:[],
      baseEscuela:[],
      prospectos2:[],
      opZona: 'TODOS',

      dialogAlumnos: false,
      totalMatriculas:[],
      tablaContactos:[],
      dialogMensajes: false,
      tablaAlumnos:[],
      dialogAlumnos: false,
      headersAlumnos:[
        { text: "alumno"        , value: "id_alumno"        },
        { text: "Nombre"        , value: "nombre_completo"  },
        { text: "Grupo"         , value: "grupo"            },
      ],

      headers3:[
        { text: "Folio"         , value: "folio"            },
        { text: "Nombre"        , value: "nombre_completo"  },
        { text: "Plantel"       , value: "plantel"          },
        { text: "Usuario"       , value: "twilio"           },
        { text: "Sistema"       , value: "sistema"          },
        { text: "Mensaje"       , value: "mensaje"          },
        { text: "Fecha"         , value: "fecha_creacion"   },
      ],
      vendedorasAsignados:[],

      coordinadoras:[],
    }),

    //Computed
    computed: {

      ...mapGetters('login',['getdatosUsuario','getEscuela']),

      formTitle () {

        return this.editedIndex === -1 ? 'Agregar un LEAD' : 'Editar LEAD'

      },

      filterTablaContactos( ){

        let data = this.tablaContactos

        if( this.opZona != 'TODOS'){
          data = data.filter( el => el.sistema == this.opZona )
        }

        return data
      },

      filterVendedoras( ){

        let data = this.vendedorasAsignados

        if( this.coordinadoras.length ){

          const soyCoordinadora = this.coordinadoras.filter( el => el.id_usuario == this.getdatosUsuario.iderp )

          if( soyCoordinadora.length ){

            let planteles = soyCoordinadora.map((registro) => { return registro.id_plantel })

            data = data.filter( el => planteles.includes( el.idplantel ) )
            
          }else{

            data = data.filter( el => el.iderp == this.getdatosUsuario.iderp )

          }

        }else{

          data = data.filter( el => el.iderp == this.getdatosUsuario.iderp )

        }

        return data
      },

      contadorVendedoras( ){

        let data = []

        for( const i in this.filterVendedoras ){

          const { nombre, iderp } = this.filterVendedoras[i]

          data.push({
            vendedora: nombre,
            contactos: this.prospectos2.filter( el => el.usuario_asignado == iderp).length,
            contactosGeneral: this.prospectos2.filter( el => el.usuario_asignado == iderp),
            iderp
          })

        }

        let uniqueArray = data.filter((obj, index, self) => 
          index === self.findIndex((el) => el.iderp === obj.iderp)
        );

        uniqueArray = uniqueArray.sort((a, b) => b.contactos - a.contactos);

        // Carga laboral
        for( const i in uniqueArray ){

          const { iderp } = uniqueArray[i]

          const existenContactos = this.contactosActivos.find( el => el.usuario_asignado == iderp )

          let contactos = existenContactos ? existenContactos.contactos : 0

          let sumaTotal = this.contactosActivos.map(item => item.contactos).reduce((prev, curr) => prev + curr, 0) 

          let carga = (( contactos / sumaTotal ) * 100 ).toFixed( 2 )

          uniqueArray[i]['carga'] = carga

          uniqueArray[i]['contactosall'] = contactos

        }
      

        // Convencimiento
        for( const i in uniqueArray ){

          const { iderp } = uniqueArray[i]

          const existenContactos = this.contactosVsFecha.filter( el => el.usuario_asignado == iderp )

          let contactos = this.contactosVsFecha.filter( el => el.usuario_asignado == iderp ).length

          let inscritos = this.contactosInscritos.filter( el => el.id_usuario_ultimo_cambio == iderp ).length


          let convencimiento = contactos > 0 ? (( inscritos / contactos ) * 100 ).toFixed( 2 ) : 0

          uniqueArray[i]['convencimiento']    = convencimiento
          uniqueArray[i]['desglose']          = `( Inscritos( ${inscritos} ) / Contactos( ${contactos} ) ) * 100 `
          uniqueArray[i]['inscritos']         = inscritos
          uniqueArray[i]['inscritos_detalle'] = this.contactosInscritos.filter( el => el.id_usuario_ultimo_cambio == iderp )


          // Primero, agregarmos las ventas del KPI al momento
          uniqueArray[i]['ventas_momento'] = this.totalMatriculas.filter( el => el.id_usuario_ultimo_cambio == iderp ).length

        }

        // Buscar la vendedora que mejor se acomode
        // Ordenar por % de convencimiento (desc) y luego por carga laboral (asc)
        uniqueArray.sort((a, b) => 
          b.convencimiento - a.convencimiento || parseFloat(a.contactos) - parseFloat(b.contactos)
        );

        // Agregar ranking y determinar si pueden recibir más contactos
        uniqueArray.forEach((vendedora, index) => {
          vendedora.puedeRecibir = (vendedora.contactos ) <= 5;
        });

        let contador3 = 0
        uniqueArray.forEach((vendedora, index) => {
          vendedora.ranking = vendedora.puedeRecibir ? contador3 + 1 : 0  ;
          contador3 += vendedora.puedeRecibir ? 1 : 0
        });

        let contador = uniqueArray.filter( el => el.ranking ).length
        uniqueArray.forEach((vendedora, index) => {
          vendedora.ranking = vendedora.ranking ? vendedora.ranking : contador + 1  ;
          contador += vendedora.ranking ? 1 : 0
        });


        // Ordenamos por ventas en kpi
        uniqueArray.sort((a, b) => b.ventas_momento - a.ventas_momento);
        for( const i in uniqueArray ){

          uniqueArray[i]['position_ventas_momento'] = parseInt( i ) + 1 

        }


        // Ahora ordenamos por contactos
        uniqueArray.sort((a, b) => b.contactos - a.contactos);

        for( const i in uniqueArray ){

          uniqueArray[i]['position'] = parseInt( i ) + 1 

        }


        for( const i in uniqueArray ){

          const { contactosGeneral, position_ventas_momento, position } = uniqueArray[i]

          uniqueArray[i]['TWILIO CDMX'] = contactosGeneral.filter( el => el.sistema == 'TWILIO CDMX' ).length

          uniqueArray[i]['TWILIO GUADALAJARA'] = contactosGeneral.filter( el => el.sistema == 'TWILIO GUADALAJARA' ).length

          uniqueArray[i]['TWILIO MONTERREY'] = contactosGeneral.filter( el => el.sistema == 'TWILIO MONTERREY' ).length

          uniqueArray[i]['TWILIO CRM INBI'] = contactosGeneral.filter( el => el.sistema == 'TWILIO CRM INBI' ).length

          uniqueArray[i]['SUCURSAL EXTERNO'] = contactosGeneral.filter( el => !el.sistema  ).length

          uniqueArray[i]['TODOS'] = contactosGeneral.length

          uniqueArray[i]['diferencia'] = position - position_ventas_momento
        }


        

        return uniqueArray
      },



    },

    watch: {
      dialogUsuario(val) {
        val || this.close();
      },
    },

    async created() {
      await this.initialize();
    },

    //Methods
    methods: {

      //Obtener prospectos y usuarios
      async initialize() {
        this.cargar = true

        await this.getVendedoras();

        await this.getProspectosAllCambiar2();
        
        await this.initialize2( )
        

      },

      getVendedoras( ){
        this.cargar = true
        this.vendedorasAsignados  = []
        return this.$http.get('vendedoras.list').then((response) => {
          // Llenamos la etapa incial
          this.vendedorasAsignados = response.data
          console.log( 'vendedoras',this.vendedorasAsignados )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getProspectosAllCambiar2(){

        this.cargar = true

        const payload = {
          fechaini: this.fechaini,
          fechafin: this.fechafin,
          escuela : this.escuela
        }

        return this.$http.post("cambiar.prospecto.obtener.prospecto", payload ).then((response) => {
          this.contactosActivos   = response.data.contactosActivos;
          this.contactosVsFecha   = response.data.contactosVsFecha;
          this.contactosInscritos = response.data.contactosInscritos;
          this.prospectos2        = response.data.prospectos;
          this.baseEscuela        = response.data.baseEscuela
          this.totalMatriculas    = response.data.totalMatriculas
          this.coordinadoras      = response.data.coordinadoras

          this.baseEscuela.push({ base: '', escuela: 0, zona: 'TODOS' },)
          this.cargar = false

          for( const i in this.prospectos2 ){

            const { nombre_completo } = this.prospectos2[i]

            this.prospectos2[i]['completo'] = nombre_completo.match('INCOMPLETO') ? '🧊' : '🔥'   
          
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      //Cerrar Dialog Vendedora
      close() {
        this.dialogUsuario = false;
        this.dialog        = false;
      },

      verContactos( value ){

        console.log( value )
        this.tablaContactos = value
        this.dialogMensajes     = true


      },

      verInscritos( value ){

        this.tablaAlumnos   = value
        this.dialogAlumnos  = true

      },

      initialize2() {
        this.cargar2 = true;

        const payload = {
          escuela: this.getEscuela
        }

        return axios.post('crm.vendedoras.json', payload ).then(response => {
        
          let vendedoras = []

          vendedoras = JSON.parse(JSON.stringify(this.contadorVendedoras));

          let twilios = response.data.reemplazos.filter( el => el.idusuario == this.getdatosUsuario.iderp ).map((registro) => { return registro.usuario_crm })

          vendedoras = vendedoras.filter( el => el.iderp == this.getdatosUsuario.iderp || twilios.includes( el.iderp ) )



          for( const  i in vendedoras ){

            const { iderp } = vendedoras[i]

            vendedoras[i]['atrasados']    = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 1 ).length 
            vendedoras[i]['nuevos']       = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 2 ).length 
            vendedoras[i]['coninfo']      = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 4 ).length 
            vendedoras[i]['sininfo']      = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 5 ).length 
            vendedoras[i]['contacto2']    = response.data.vendedoras.filter( el => el.usuario_asignado == iderp && el.idetapa == 7 ).length 
            vendedoras[i]['porcontestar'] = response.data.vendedoras.filter( el => el.usuario_asignado == iderp ).length 

          }


          let contactosTwilio = response.data.chatsCompletos

          for( const  i in vendedoras ){

            const { iderp } = vendedoras[i]

            let contactos = contactosTwilio.filter( el => el.nombre_completo )

            vendedoras[i]['amarillos']   = contactos.filter( el => el.usuario_asignado == iderp && ( el.tiemporestante > 0 && el.tiemporestante <= 2 ) && el.mio == 0 ).length 
            vendedoras[i]['amarillos_C'] = contactos.filter( el => el.usuario_asignado == iderp && ( el.tiemporestante > 0 && el.tiemporestante <= 2 ) && el.mio == 0 ) 
            vendedoras[i]['verdes']      = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 2 && el.mio == 0 ).length 
            vendedoras[i]['verdes_C']    = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 2 && el.mio == 0 ) 
            vendedoras[i]['rojos']       = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante <= 0 && el.mio == 0 ).length 
            vendedoras[i]['rojos_C']     = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante <= 0 && el.mio == 0 ) 
            vendedoras[i]['espera']      = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante >= 0 && el.mio == 0 ).length 
            vendedoras[i]['espera_C']    = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante >= 0 && el.mio == 0 ) 


            vendedoras[i]['amarillos_mios']   = contactos.filter( el => el.usuario_asignado == iderp && ( el.tiemporestante > 0 && el.tiemporestante <= 2 ) && el.mio == 1 ).length 
            vendedoras[i]['amarillos_mios_C'] = contactos.filter( el => el.usuario_asignado == iderp && ( el.tiemporestante > 0 && el.tiemporestante <= 2 ) && el.mio == 1 ) 
            vendedoras[i]['verdes_mios']      = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 2 && el.mio == 1 ).length 
            vendedoras[i]['verdes_mios_C']    = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 2 && el.mio == 1 ) 
            vendedoras[i]['rojos_mios']       = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante <= 0 && el.mio == 1 ).length 
            vendedoras[i]['rojos_mios_C']     = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante <= 0 && el.mio == 1 ) 

            let contactosGenerales = contactos.filter( el => el.usuario_asignado == iderp && el.mio == 1 )

            contactosGenerales = contactosGenerales.sort((a, b) => new Date(b.fecha_ultimo_mensaje) - new Date(a.fecha_ultimo_mensaje));

            let ultimoContacto = contactosGenerales[0]

            if( ultimoContacto ){

              vendedoras[i]['utlimocontacto']       =  ultimoContacto.minutos_transcurridos
              vendedoras[i]['utlimomensaje']        =  ultimoContacto
              vendedoras[i]['tiempo_espminutosera'] =  ultimoContacto.tiempo_espera
              vendedoras[i]['minutos']              =  ultimoContacto.minutos

            }

            // Buscar el contacto con mayor tiempo de espera
            let contactosEnEspera = contactos.filter( el => el.usuario_asignado == iderp && el.tiemporestante > 0 && el.mio == 0 )

            contactosEnEspera = contactosEnEspera.sort((a, b) => new Date(b.tiempo_espera_usuario) - new Date(a.tiempo_espera_usuario));

            if( contactosEnEspera.length ){

              vendedoras[i]['contacto_espera_mayor_tiempo'] =  contactosEnEspera[0].tiempo_espera

            }
          }


          this.vendedoras = JSON.parse(JSON.stringify(vendedoras));

          this.cargar2 = false;

        }).catch(error => {
          this.validarError(error.response.data.message);
        }).finally(() => {
          this.cargar = false;
        });
      },
    },
  };
</script>